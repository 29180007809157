<!-- 已安装设备 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              clearable
              placeholder="请输入设备号"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="deviceMacInput"
            ></el-input>
            <el-input
              clearable
              placeholder="请输入充电站名称"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="orgNameInput"
            ></el-input>
            <el-input
              clearable
              placeholder="请输入设备地址"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="deviceAddressInput"
            ></el-input>
            <el-select
                clearable
                class="search-input"
                placeholder="请输入设备状态"
                size="small"
                @change="searchInput"
                v-model="deviceStatusInput">
              <el-option
                  v-for="item in deviceStatusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >{{ item.label }}</el-option>
            </el-select>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择代理商"
              size="small"
              @change="searchInput"
              v-model="deviceAgencyInput"
            >
              <el-option
                v-for="item in deviceAgencyList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              >{{ item.merchantName }}</el-option>
            </el-select>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择收费方案"
              size="small"
              v-model="deviceChargeInput"
              @change="searchInput"
            >
              <el-option
                v-for="item in chargingSchemeData"
                :key="item.chargeId"
                :label="item.chargeName"
                :value="item.chargeId"
              >{{ item.chargeName }}</el-option>
            </el-select>
            <el-button type="primary" size="small" @click="searchClick">搜索</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 按钮操作区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24">
            <el-button type="primary" size="small" @click="handelClickEditAdd" v-if="userLevel === 0">
              <el-icon :size="15"> <plus /> </el-icon><span>新建</span>
            </el-button>
            <el-button type="danger" size="small" @click="batchDelete" plain v-if="userLevel === 0">
              <el-icon :size="15"> <delete-filled /> </el-icon><span>批量删除</span>
            </el-button>
<!--            <el-button type="primary" size="small" @click="batchAllocationClick">-->
<!--              批量分配-->
<!--            </el-button>-->
            <el-button type="primary" size="small" @click="chargingSchemeClick" v-if="userLevel === 0">
              批量设置收费方案
            </el-button>
            <el-button type="primary" size="small" @click="downloadDevice">
              导出Excel
            </el-button>
            <el-button type="primary" size="small" @click="downloadExcel" v-if="userLevel === 0">
              导出模板
            </el-button>
            <label for="my-uploader" v-if="userLevel === 0">
              <div class="import-btn-class">导入设备</div>
            </label>
            <input
                id="my-uploader"
                style="display: none"
                ref="uploaderInputRef"
                accept=".xls"
                type="file"
                @change="importDevice" />
            <el-button type="primary" size="small" @click="deviceSync" v-if="userLevel === 0">
              同步设备状态
            </el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="80" />
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
            <template  #default="{ row }" v-if="item.prop === 'deviceStatus'">
              {{row.deviceStatus === 0 ? '正常' : '异常'}}
            </template>
            <template  #default="{ row }" v-if="item.prop === 'chargeType'">
              {{row.chargeType === '0' ? '计时' : '电量'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="{ row }">
              <el-button type="text" @click="viewDetailsClick(row)">详情</el-button>
              <el-divider  v-if="userLevel === 0" direction="vertical"></el-divider>
              <el-button v-if="userLevel === 0" type="text" @click="editClick(row)">编辑</el-button>
              <el-divider v-if="userLevel === 0" direction="vertical"></el-divider>
              <el-popconfirm
                  v-if="userLevel === 0"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="confirmEvent(row)"
                :title="'确认是否删除' + '“' + row.deviceMac + '”' + '？'"
              >
                <template #reference>
                  <el-button type="text">删除</el-button>
                </template>
              </el-popconfirm>
              <!-- <el-divider direction="vertical"></el-divider>
              <el-button type="text">分配设备</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text">收费方案</el-button> -->
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="setQrcode(row)">生成二维码</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>

    <!-- 新建设备 -->
    <el-dialog
      v-model="visible"
      width="40%"
      :title="titleName"
      :close-on-click-modal="false"
      @closed="resetForm"
    >
      <el-form
        label-width="115px"
        :inline="true"
        :model="formData"
        :rules="addRules"
        ref="ruleForm"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="设备imei号：" prop="deviceImei">
              <el-input
                placeholder="请输入设备imei号"
                v-model="formData.deviceImei"
                :disabled="viewDetailDisabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备号：" prop="deviceMac">
              <el-input
                  placeholder="请输入设备号"
                  v-model="formData.deviceMac"
                  :disabled="viewDetailDisabled"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="设备代理商：" prop="deviceAgency">
              <el-select
                  v-model="formData.deviceAgency"
                  placeholder="请选择代理商"
                  size="small"
                  :disabled="viewDetailDisabled"
                  class="search-input-class"
                  @change="deviceAgencyChange" >
                <el-option
                    v-for="item in deviceAgencyList"
                    :key="item.merchantId"
                    :label="item.merchantName"
                    :value="item.merchantId" >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="充电站：" prop="stationId">
              <el-select
                  v-model="formData.stationId"
                  size="small"
                  placeholder="请选择充电站"
                  :disabled="viewDetailDisabled || isChangeDeviceAgency"
                  class="search-input-class"
                  @change="jointFn" >
                <el-option
                    v-for="item in stationNameOptions"
                    :key="item.stationId"
                    :label="item.stationName"
                    :value="item.stationId" >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="设备端口数：" prop="devicePortNum">
              <el-input
                placeholder="请输入设备端口数"
                v-model="formData.devicePortNum"
                :disabled="viewDetailDisabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备版本：" prop="deviceVersion">
              <el-input
                  placeholder="请输入设备版本"
                  v-model="formData.deviceVersion"
                  :disabled="viewDetailDisabled"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item label="设备类型：" prop="deviceType">
              <el-select
                v-model="formData.deviceType"
                placeholder="请选择设备类型"
                @change="jointFn"
              >
                <el-option
                  v-for="item in auditType"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :gutter="20">
          <!-- <el-col :span="24">
            <el-form-item label="设备型号：" prop="deviceModel">
              <el-input
                placeholder="请输入设备型号"
                v-model="formData.deviceModel"
                :disabled="viewDetailDisabled"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="设备地址：" prop="deviceAddress">
              <el-input
                placeholder="请输入设备地址"
                v-model="formData.deviceAddress"
                :disabled="viewDetailDisabled"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="titleName === '设备详情' ">
          <el-row :gutter="20" v-show="formData.devicePortNum == 2">
            <el-col :span="10" :offset="2" v-for="item in portList" :key="item.port"  style="margin: 0 0 20px 40px">
              {{item.port}}
              <el-tag type="success" v-show="item.portStatus === '空闲'">空闲</el-tag>
              <el-tag type="danger" v-show="item.portStatus !== '空闲'">占用</el-tag>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-show="formData.devicePortNum == 10">
            <el-col :span="4.5"  v-for="item in portList" :key="item.port" style="margin: 0 0 20px 20px">
              {{item.port}}
              <el-tag type="success" v-show="item.portStatus === '空闲'">空闲</el-tag>
              <el-tag type="danger" v-show="item.portStatus !== '空闲'">占用</el-tag>
            </el-col>
          </el-row>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">取消 </el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">{{titleName === '设备详情' ? '确定' : '保存'}}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 分配设备 -->
    <el-dialog
        v-model="allocateDeviceVisible"
        width="40%"
        :title="titleName"
        :close-on-click-modal="false"
        @closed="resetForm"
    >
      <el-form
          label-width="95px"
          :inline="true"
          :model="allocateDeviceFormData"
          :rules="allocateDeviceRules"
          ref="allocateDeviceRuleFormRef"
      >
      <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="代理商：" prop="agent">
              <el-select
                  v-model="allocateDeviceFormData.agent"
                  placeholder="请选择代理商"
                  class="search-input-class"
                  @change="jointFn" >
                <el-option
                    v-for="item in deviceAgencyList"
                    :key="item.merchantId"
                    :label="item.merchantName"
                    :value="item.merchantId" >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      <el-row :gutter="20">
        <el-col :span="22">
          <el-form-item label="充电站：" prop="network">
            <el-select
                v-model="allocateDeviceFormData.network"
                placeholder="请选择充电站"
                class="search-input-class"
                @change="jointFn" >
              <el-option
                  v-for="item in auditType"
                  :key="item"
                  :label="item"
                  :value="item" >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetAllocateDeviceForm">取消 </el-button>
          <el-button type="primary" @click="submitAllocateDeviceForm('allocateDeviceRuleFormRef')" >保存 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量设置收费方案 -->
    <el-dialog
        v-model="chargingSchemeVisible"
        width="40%"
        :title="chargingSchemeTitleName"
        :close-on-click-modal="false"
        @closed="resetForm"
    >
      <el-form
          label-width="95px"
          :inline="true"
          :model="chargingSchemeFormData"
          :rules="chargingSchemeRules"
          ref="chargingSchemeFormRef"
      >
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="设备号：">
              <el-input
                  placeholder="请输入设备号"
                  v-model="chargingSchemeFormData.ids"
                  readonly
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="收费类型：">
              <el-radio-group v-model="activeChargeTypeRadio" @change="chargeTypeRadioChange">
                <el-radio
                    v-for="item in chargeTypeOptions"
                    :key="item.value"
                    :label="item.value"
                >{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="收费方案：" prop="deviceChargnt">
              <el-select
                  v-model="chargingSchemeFormData.deviceChargnt"
                  placeholder="请选择收费方案"
                  class="search-input-class"
              >
                <el-option
                    v-for="item in chargingSchemeOption"
                    :key="item.chargeId"
                    :label="item.chargeName"
                    :value="item.chargeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetChargingSchemeForm">取消 </el-button>
          <el-button type="primary" @click="submitChargingSchemeForm('chargingSchemeFormRef')" >保存 </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 生成设备二维码 -->
    <el-dialog
        v-model="settingQrcode"
        :width="devicePortNum == 2?'25%':'50%'"
        title="设备二维码"
        :close-on-click-modal="true"
        :before-close="qrcodeClose"
        @closed="settingQrcode = false"
    >
      <div class="qrcode" v-if="devicePortNum == 2">
        <div class="qrcode-l" ref="qrCodeUrl"></div>
      </div>
      <div class="qrcode" v-else>
        <div class="qrcode-l" ref="qrCodeUrl1">端口1</div>
        <div class="qrcode-l" ref="qrCodeUrl2">端口2</div>
        <div class="qrcode-l" ref="qrCodeUrl3">端口3</div>
        <div class="qrcode-l" ref="qrCodeUrl4">端口4</div>
        <div class="qrcode-l" ref="qrCodeUrl5">端口5</div>
        <div class="qrcode-l" ref="qrCodeUrl6">端口6</div>
        <div class="qrcode-l" ref="qrCodeUrl7">端口7</div>
        <div class="qrcode-l" ref="qrCodeUrl8">端口8</div>
        <div class="qrcode-l" ref="qrCodeUrl9">端口9</div>
        <div class="qrcode-l" ref="qrCodeUrl10">端口10</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import {
  deviceSelectPagesApi,
  addDeviceApi,
  delDeviceApi,
  upDeviceAPi,
  getChargingSchemeAPi,
  updChargeAPi,
  deriveXlsAllAPi,
  exportTemplateAPi,
  importXlsAPi,
  getByDeviceMacAPi,
  deviceSyncApi,
} from "@/api/deviceManagement/deviceManagement.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import {nextTick, ref} from "vue";
import {getAgencyApi} from "@/api/synthesisAnalysis/deviceAnalysis";
import {selectPagesApi} from "@/api/chargingStationManagement/chargingStationManagement";
import QRCode from 'qrcodejs2'

export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const allocateDeviceRuleFormRef = ref();
    const data = reactive({
      searchValue: null, //搜索内容（用户名）
      searchContent: null, //搜索日志内容
      tableData: [], // 表格数据
      tableColumnData: [
        {
          prop: "deviceMac",
          label: "设备号",
        },
        {
          prop: "deviceSignal",
          label: "设备信号",
        },
        {
          prop: "deviceVersion",
          label: "设备版本",
        },
        {
          prop: "deviceImei",
          label: "imei号",
        },
        {
          prop: "merchantName",
          label: "代理商",
        },
        {
          prop: "stationName",
          label: "充电站名称",
        },
        {
          prop: "devicePortNum",
          label: "端口数",
        },
        {
          prop: "deviceAddress",
          label: "设备地址",
        },
        {
          prop: "deviceStatus",
          label: "设备状态",
        },
        {
          prop: "chargeType",
          label: "收费类型",
        },
        {
          prop: "chargeName",
          label: "收费方案",
        },
      ],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      titleName: "",
      visible: false,
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      viewDetailDisabled: false, // 查看详情不可编辑
      isChangeDeviceAgency: true, //是否没选择设备代理商
      changedDeviceAgency: '', //被选择设备代理商的名称
      formData: {
        deviceImei: "", // imei号
        deviceVersion: "", // 设备版本
        deviceAgency: "", // 代理商
        devicePortNum: null, // 端口号
        // deviceModel: "", // 设备型号
        deviceAddress: "", // 设备地址
        deviceChargnt: 1, // 收费方案
        deviceMac: "",
        stationId: ""
      },
      addRules: {
        deviceVersion: [
          { required: true, message: '请输入设备版本', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
        ],
        deviceImei: [
          { required: false, message: '请输入设备imei号', trigger: 'blur' },
        ],
        deviceAgency: [
          { required: true, message: '请选择代理商', trigger: ['blur','change'] },
        ],
        stationId: [
          { required: true, message: '请选择充电站', trigger: ['blur','change'] },
        ],
        devicePortNum: [
          { required: true, message: '请输入设备端口', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '请输入大于0的整数', trigger: 'blur'}
        ],
        // deviceModel: [
        //   { required: false, message: '请输入设备型号', trigger: 'blur' },
        // ],
        deviceAddress: [
          { required: true, message: '请输入设备地址', trigger: 'blur' },
        ],
        deviceChargnt: [
          { required: false, message: '请选择收费方案', trigger: 'blur' },
        ],
        deviceMac: [
          { required: false, message: '请输入设备号', trigger: 'blur' },
        ],
      },

      allocateDeviceVisible: false, // 分配设备对话框
      // 分配设备form
      allocateDeviceFormData: {
        agent:'',
        network: ''
      },
      allocateDeviceRules: {
        agent: [
          { required: true, message: '请选择代理商', trigger: 'change',}
        ]
      },

      chargingSchemeVisible: false, // 批量设置收费方案对话框
      chargingSchemeTitleName: '批量设置收费方案',
      chargingSchemeFormData: {
        ids:'',
        deviceChargnt: ''
      },
      chargingSchemeRules: {
        deviceChargnt: [
          { required: true, message: '请选择收费方案', trigger: 'change',}
        ]
      },

      settingQrcode: false,   // 生成二维码按钮对话框
      qrcode: '',   // 二维码内容

      deviceMacInput: "",
      orgNameInput: "",
      deviceAddressInput: "",
      deviceStatusInput: "",
      deviceAgencyInput: "",
      deviceChargeInput: "",
      // 设备状态下拉框
      deviceStatusOption: [
        { value: 0, label: '正常'},
        { value: 1, label: '异常'},
      ],

      deviceAgencyList: [], //代理商选项
      chargeTypeOptions: [
        { value:0, label:'计时'},
        // { value:1, label:'电量'},
      ],
      activeChargeTypeRadio: 0, //选中收费类型的值
      chargingSchemeData: [], // 请求的收费方案
      chargingSchemeOption:[], // 收费方案选项
      stationNameOptions: [], //充电站名称选择项
      portList: [], // 端口状态列表
      devicePortNum: 0,   // 当前选中生成二维码的端口数量
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() =>{
        proxy.userLevel = proxy.userInfo.chargingMerchant.level
      })
      methods.selectPages();
      methods.getChargingScheme()
      methods.getAgency()
    });

    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.selectPages();
    }, 500);

    const methods = {
      searchClick() {
        methods.selectPages();
      },
      // 查询旗下代理商户信息
      getAgency() {
        requestApi(() => {
          return getAgencyApi({merchantId:data.userInfo.merchantId})
        },(res) => {
          data.deviceAgencyList = res.data.map((it) => {
            return {
              merchantName: it.merchantName,
              merchantId: it.merchantId + ''
            }
          })
        })
      },
      // 设备分页查询
      async selectPages() {
        await requestApi(
          () => {
            const params = {
              current: data.currentPage,
              pageSize: data.pageSize,
              address: data.deviceAddressInput,
              deviceAgency: data.deviceAgencyInput,
              deviceCharge: data.deviceChargeInput,
              deviceStatus: data.deviceStatusInput,
              deviceMac: data.deviceMacInput,
              orgName: data.orgNameInput
            }
            return deviceSelectPagesApi(params);
          },
          (res) => {
            proxy.tableData = res.data.records
            if(proxy.tableData.length > 0) {
              for (let i = 0; i < proxy.tableData.length; i++) {
                proxy.tableData[i].stationId = proxy.tableData[i].stationId + ''
              }
            }
            // console.log(proxy.tableData, 'proxy.tableData ')
            proxy.total = res.data.total
            proxy.currentPage = res.data.current
            proxy.pageSize = res.data.size
          }
        );
      },
      // 充电站查询
      async stationNameSelectPages() {
        if(proxy.titleName != '编辑设备') {
          proxy.formData.stationId = ''
        }
        // proxy.formData.stationId = ''
        await requestApi(
            () => {
              const params = {
                current: data.currentPage,
                size: data.pageSize,
                address: '',
                merchantName: data.changedDeviceAgency
              }
              return selectPagesApi(params);
            },
            (res) => {
              proxy.stationNameOptions = res.data.records
              if(proxy.stationNameOptions.length > 0) {
                for (let i = 0; i < proxy.stationNameOptions.length; i++) {
                  proxy.stationNameOptions[i].stationId = proxy.stationNameOptions[i].stationId + ''
                }
              }
            }
        );
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val
        methods.selectPages()
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val
        methods.selectPages()
      },
      // 新增
      handelClickEditAdd() {
        proxy.titleName = "新增设备";
        proxy.visible = true;
        proxy.viewDetailDisabled = false
        delete proxy.formData.deviceId
        delete proxy.formData.deviceStatus
        proxy.formData = {}
      },
      // 查看详情
      viewDetailsClick(data) {
        proxy.titleName = "设备详情";
        proxy.visible = true;
        proxy.viewDetailDisabled = true
        proxy.formData = JSON.parse(JSON.stringify(data));
        proxy.stationNameOptions = [{
          stationName: data.stationName,
          stationId: data.stationId
        }]
        methods.getByDeviceMac(proxy.formData.deviceMac,proxy.formData.devicePortNum)
      },
      // 根据设备MAC查询占用的端口
      getByDeviceMac(deviceMac,devicePortNum) {
        requestApi(() => {
          return getByDeviceMacAPi({deviceMac: deviceMac})
        },(res) => {
          data.portList = []
          devicePortNum = Number(devicePortNum)
          // 先把所有的端口设为空闲
          for (let i = 0; i < devicePortNum; i++) {
            data.portList.push({port:`端口${ i + 1 }：` , portStatus: '空闲'})
          }
          // 修改占用的端口
          if(res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              data.portList[res.data[i].device_port - 1].portStatus = '占用'
            }
          }
        })
      },
      // 编辑
      editClick(data) {
        if (proxy.userLevel === 0) {
          proxy.titleName = "编辑设备";
          proxy.visible = true;
          proxy.viewDetailDisabled = false
          nextTick(() => {
            proxy.formData = JSON.parse(JSON.stringify(data));
            // console.log(proxy.formData, 'bianj')
            proxy.isChangeDeviceAgency = false
            proxy.changedDeviceAgency = proxy.formData.merchantName
            methods.stationNameSelectPages()
          })
        } else {
          proxy.$message({
            message: '超级管理员才能操作',
            type: 'warning',
          })
        }
      },
      // 设备代理商选项change
      deviceAgencyChange(val) {
        // console.log(data.formData.deviceAgency,val,'change')
        if (data.formData.deviceAgency) {
          // 选择设备代理商后
          data.isChangeDeviceAgency = false
          const arr = data.deviceAgencyList.filter(it => it.merchantId == data.formData.deviceAgency)
          data.changedDeviceAgency = arr[0].merchantName
          proxy.formData.stationId = ''
          methods.stationNameSelectPages()
        }
      },
      // 保存
      submitForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (proxy.titleName === "新增设备") {
              console.log(proxy.formData);
              await methods.addDevice(proxy.formData)
            } else if(proxy.titleName === "编辑设备") {
              await methods.updateDevice(proxy.formData);
            }
            proxy.visible = false;
            await methods.selectPages();
          }
        });
      },
      // 新增设备
      async addDevice() {
        await requestApi(
            () => {
              return addDeviceApi(proxy.formData);
            },
            (res) => {
              if (res.code === 200) {
                proxy.$notify({
                  title: "成功",
                  message: res.message,
                  type: "success",
                  duration: 1000,
                });
              }
            }
        );
      },
      // 修改设备
      async updateDevice(data) {
        await requestApi(
            () => {
              return upDeviceAPi(data)
            },
            (res) => {
              if (res.code === 200) {
                proxy.$notify({
                  title: "成功",
                  message: '编辑设备成功',
                  type: "success",
                  duration: 1000,
                });
              }
            }
        );
      },
      // 取消
      resetForm() {
        proxy.visible = false;
        proxy.$refs.ruleForm.resetFields()
        data.isChangeDeviceAgency = true
      },
      // 勾选
      handleSelectionChange(val) {
        proxy.selection = val.map((it) => it.deviceId);
      },
      // 批量删除
      batchDelete() {
        if (proxy.selection.length > 0) {
          proxy.$msgbox
            .confirm("确认是否删除所选数据?", "删除", {
              closeOnClickModal: false,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(async () => {
              // console.log(proxy.selection)
              methods.delDevice(proxy.selection)
            })
            .catch(() => {
              proxy.$refs.tableRef.clearSelection();
            });
        }
      },
      // 点击表格 删除
      confirmEvent(data) {
        if (proxy.userLevel === 0) {
          methods.delDevice([data.deviceId])
        } else {
          proxy.$message({
            message: '超级管理员才能操作',
            type: 'warning',
          })
        }
      },

      // 删除设备
      async delDevice(data) {
        await requestApi(
          () => {
            return delDeviceApi(data)
          },
          (res) => {
            if(res.code === 200) {
              proxy.$notify({
                title: '成功',
                message: res.message,
                type: "success",
                duration: 1000
              })
              methods.selectPages()
            }
          }
        )
      },
      // 批量分配
      batchAllocationClick() {
        data.allocateDeviceVisible = true
      },
      // 点击确认  分配设备
      submitAllocateDeviceForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            data.allocateDeviceVisible = false
          } else {
            console.log('error submit!')
          }
        })
      },
      // 取消 分配设备
      resetAllocateDeviceForm() {
        data.allocateDeviceVisible = false
        proxy.$refs.allocateDeviceRuleFormRef.resetFields()
      },
      // 获取收费方案
      getChargingScheme() {
        requestApi(
            () => {
              return getChargingSchemeAPi({
                current:1,
                size: 1000,
                chargeType: 0
              })
            },
            (res) => {
              proxy.chargingSchemeData = res.data.records
              // 默认activeChargeTypeRadio = 0
              proxy.chargingSchemeOption = proxy.chargingSchemeData.filter((it) => it.chargeType === 0)
            }
        )
      },
      // radio绑定值变化时触发的事件
      chargeTypeRadioChange() {
        if(proxy.activeChargeTypeRadio === 0) {
          //  计时
          proxy.chargingSchemeOption = proxy.chargingSchemeData.filter((it) => it.chargeType === 0)
          proxy.chargingSchemeFormData.deviceChargnt = ''
        } else {
          proxy.chargingSchemeOption = proxy.chargingSchemeData.filter((it) => it.chargeType === 1)
          proxy.chargingSchemeFormData.deviceChargnt = ''
        }
      },
      // 点击批量设置收费方案
      chargingSchemeClick() {
        if (proxy.selection.length > 0) {
          data.chargingSchemeVisible = true
          data.chargingSchemeFormData.ids = proxy.selection.toString()
        } else {
          proxy.$message({
            message: '请选择要设置收费方案的设备',
            type: 'warning',
          })
        }
      },
      // 点击确认  收费方案
      submitChargingSchemeForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            data.chargingSchemeVisible = false
            await methods.updCharge(data.chargingSchemeFormData)
            await methods.selectPages();
          } else {
            // console.log('error submit!')
          }
        })
      },
      // 批量或单个分配收费方案
      async updCharge(params) {
       await requestApi(
            () => {
              return updChargeAPi(params)
            },
            (res) => {
              proxy.$notify({
                title: '成功',
                message: res.message,
                type: "success",
                duration: 1000
              })
            }
        )
      },
      // 取消 收费方案
      resetChargingSchemeForm() {
        data.chargingSchemeVisible = false
        proxy.$refs.chargingSchemeFormRef.resetFields()
      },
      qrcodeClose() {
        // return console.log(proxy.$refs.qrCodeUrl1);
        if(proxy.devicePortNum == 2) {
          proxy.$refs.qrCodeUrl.innerHTML = ''
        } else {
          proxy.$refs.qrCodeUrl1.innerHTML = ''
          proxy.$refs.qrCodeUrl2.innerHTML = ''
          proxy.$refs.qrCodeUrl3.innerHTML = ''
          proxy.$refs.qrCodeUrl4.innerHTML = ''
          proxy.$refs.qrCodeUrl5.innerHTML = ''
          proxy.$refs.qrCodeUrl6.innerHTML = ''
          proxy.$refs.qrCodeUrl7.innerHTML = ''
          proxy.$refs.qrCodeUrl8.innerHTML = ''
          proxy.$refs.qrCodeUrl9.innerHTML = ''
          proxy.$refs.qrCodeUrl10.innerHTML = ''
        }
        proxy.settingQrcode = false
      },
      // 生成设备mac号二维码
      setQrcode(row) {
        proxy.settingQrcode = true
        proxy.devicePortNum = row.devicePortNum
        // if(proxy.qrcode) {  // 有新的二维码地址了，先把之前的清除掉
        //   proxy.$refs.qrCodeUrl.innerHTML = ''
        // }
        setTimeout(() => {
          let arr = []
          if(row.devicePortNum == 2) {
            arr = [proxy.$refs.qrCodeUrl]
          } else {
            arr = [
                proxy.$refs.qrCodeUrl1,
                proxy.$refs.qrCodeUrl2,
                proxy.$refs.qrCodeUrl3,
                proxy.$refs.qrCodeUrl4,
                proxy.$refs.qrCodeUrl5,
                proxy.$refs.qrCodeUrl6,
                proxy.$refs.qrCodeUrl7,
                proxy.$refs.qrCodeUrl8,
                proxy.$refs.qrCodeUrl9,
                proxy.$refs.qrCodeUrl10
            ]
          }
          arr.forEach((item, index) => {
            // console.log(arr, item);
            // let data = {
            //   deviceMac: row.deviceMac,
            //   devicePortNum: row.devicePortNum,
            //   select: index + 1
            // }
            let data = 'https://charge.teruisa.com/weixin/124?deviceMac=' + row.deviceMac + '&devicePortNum=' + row.devicePortNum + '&select=' + (index + 1)
            // let data = 'https://charge.teruisa.com/weixin/124?deviceMac=1901200001&devicePortNum=2&select=1'
            proxy.qrcode = new QRCode(item, {
              text: data, // 需要转换为二维码的内容
              width: 150,
              height: 150,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H
            })
          })
        }, 0);
      },
      // 导出设备
      downloadDevice () {
        if (proxy.selection.length > 0) {
          proxy.$msgbox
            .confirm('确认是否将选中的数据导出?', '导出', {
              closeOnClickModal: false,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
              methods.deriveXlsAll()
            }).catch(() => {
              proxy.$refs.tableRef.clearSelection()
          })
        } else {
          proxy.$msgbox
              .confirm('确认是否将全部数据导出?', '导出', {
                closeOnClickModal: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
              .then(() => {
                // proxy.tableData.map(it => {
                //   proxy.selection.push(it.deviceId)
                // })
                methods.deriveXlsAll()
              }).catch(() => {
            proxy.$refs.tableRef.clearSelection()
          })
        }
      },
      // 导出设备接口
      deriveXlsAll () {
        const ids = proxy.selection + ''
        const params = {
          ids: ids,
          merchantId: data.userInfo.merchantId,
          level: proxy.userLevel

        }
        requestApi(
          () => {
            return deriveXlsAllAPi(params)
          },
          (res) => {
            methods.downloadFile(res,'设备管理')
          }
        )
      },
      // 下载模板
      downloadExcel() {
        requestApi(
          () => {
            return exportTemplateAPi()
          },
          (res) => {
            methods.downloadFile(res,'设备管理模板')
          }
        )
      },
      // 处理下载请求
      downloadFile(res,name) {
        const url = URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        document.body.appendChild(a); // 此处增加了将创建的添加到body当中
        a.href = url;
        a.download = name + new Date().getTime() + ".xls";
        a.target = "_blank";
        a.click();
        a.remove(); // 将a标签移除
        proxy.$refs.tableRef.clearSelection();
      },
      // 导入设备
      importDevice(event) {
        // 限制文件上传的个数
        let length = event.target.files.length
        if (length > 2) {
          proxy.$notify({
            title: '提示',
            message: "每次只能上传一个文件",
            type: 'success',
            duration: 1000
          })
          return;
        }
        /* 限制文件上传大小 */
        let file = event.target.files[0];
        let isLtSize = file.size / 1024 / 1024 < 10;
        if (!isLtSize) {
          proxy.$notify({
            title: '提示',
            message: "上传文件不能超过 10MB!",
            type: 'warning',
            duration: 1000
          })
          return;
        }

        let formData = new FormData()
        formData.append('merchantId', data.userInfo.merchantId)
        formData.append('file', file)
        methods.importXls(formData)
        this.$refs.uploaderInputRef.value = null;
      },
      // 导入接口
      importXls(formData) {
        requestApi(
          () => {
            return importXlsAPi(formData)
          },
          (res) => {
            proxy.$notify({
              title: '成功',
              message: res.message,
              type: 'success',
              duration: 1000
            })
            methods.selectPages()
          }
        )
      },
      // 同步设备状态
      async deviceSync() {
       const deviceMacs = data.tableData.map(it => it.deviceMac)
       const formdata = new FormData()
       formdata.append('deviceMacs', deviceMacs)
       await requestApi(
            () => {
              return deviceSyncApi(formdata)
            },
            (res) => {
              methods.selectPages()
              proxy.$notify({
                title: '成功',
                message: res.message,
                type: "success",
                duration: 1000
              })
            }
        )
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
      allocateDeviceRuleFormRef,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
  .formClass {
    display: inline-block;
    margin-right: 4px;
  }
}
.page-container {
  width: 100%;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.selectClass {
  display: inline-block;
  width: 300px;
  margin-right: 10px;
  .biaoti {
    color: #343434;
    font-size: 16px;
  }
}
.search-input-class {
  width: 100%;
}
.import-btn-class {
  display: inline;
  margin: 0 10px;
  min-height: 32px;
  padding: 8px 15px;
  font-size: 12px;
  color: #fff;
  background: #409eff;
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;
}

/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
.qrcode {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.qrcode-l {
  margin: 20px;
}
</style>
